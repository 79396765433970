<template>
	<div class="myGrade">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">我的年级</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="class-list" v-if="GradeList.length">
					<div class="class-item m_b_20" v-for="(item,index) in GradeList" :key="index">
						<div style="padding: 15px 15px 20px 15px;">
							<div class="u-f-item">
								<span class="font_blod">{{item.name}}</span>
							</div>
							<div class="u-f-item m_t_20">
								年级长：
								<div class="subject" v-for="(top,i) in item.top" :key="i">{{top.teacher_name}}</div>
							</div>
						</div>
						<div class="u-f-justify btn" style="padding: 15px 0;background-color: #FAFAFA;color: #505050;" @click="toUrl">查看详情</div>
					</div>
				</div>
				<el-empty :image-size="100" v-if="!GradeList.length" style="margin-top: 200px;"></el-empty>
			</div>
		</index>
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	export default {
		components: {
			index
		},
		data() {
			return {
				GradeList:[]
			};
		},
		mounted() {
			this.getmyGrade()
		},
		methods: {
			toUrl(){
				// this.$router.push('/myClassDetails');
			},
			getmyGrade(){
				this.$api.rule.getmyGrade({
					teacher_id:window.localStorage.getItem("id")
				}).then(res=>{
					this.GradeList = res.data.data;
				})
			}
		}
	};
</script>

<style lang="scss">
	.myGrade{
		.content{
			background-color: #FFFFFF;
			height: 80vh;
			margin-top: 1.04166vw;
			padding: 1.302vw;
			.class-list{
				display: flex;
				flex-wrap: wrap;
				.class-item{
					width: 23%;
					border: 1px solid #EBEDEE;
					margin-right: 20px;
					.icon-banji11{
						color: #60B2FF;
						font-size: 26px;
						margin-right: 5px;
					}
					.subject{
						background-color: #F1F1F1;
						font-size: 0.625vw;
						padding: 0.26vw 0.7812vw;
					}
					.btn{
						cursor: pointer;
					}
					.btn:hover{
						background-color: #EEEEEE!important;
					}
				}
				
			}
		}
	}
</style>
